import { useLogService } from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import { setupLocalEnv } from './setupLocalEnv';

if (process.env.NODE_ENV === 'development') {
  if (process.env.STANDALONE) {
    setupLocalEnv();
  }
}

// Load the Atlas CSS Styles
const azureMapStyles = document.createElement('link');
azureMapStyles.setAttribute('type', 'text/css');
azureMapStyles.setAttribute('rel', 'stylesheet');
azureMapStyles.setAttribute('href', 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.css');

// Load the Atlas JavaScript SDK
const azureMapScripts = document.createElement('script');
azureMapScripts.setAttribute('type', 'text/javascript');
azureMapScripts.setAttribute('src', 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.js');

// Load the Atlas Drawing Tools CSS Styles
const azureMapDrawingStyles = document.createElement('link');
azureMapDrawingStyles.setAttribute('type', 'text/css');
azureMapDrawingStyles.setAttribute('rel', 'stylesheet');
azureMapDrawingStyles.setAttribute('href', 'https://atlas.microsoft.com/sdk/javascript/drawing/1/atlas-drawing.min.css');

// Load the Atlas Drawing Tools JavaScript SDK
const azureMapDrawingScripts = document.createElement('script');
azureMapDrawingScripts.setAttribute('type', 'text/javascript');
azureMapDrawingScripts.setAttribute('src', 'https://atlas.microsoft.com/sdk/javascript/drawing/1/atlas-drawing.min.js');

document.head.insertAdjacentElement('beforeend', azureMapStyles);
document.head.insertAdjacentElement('beforeend', azureMapScripts);
document.head.insertAdjacentElement('beforeend', azureMapDrawingStyles);
document.head.insertAdjacentElement('beforeend', azureMapDrawingScripts);

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    useLogService().error(error);

    return <></>;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;
