import permissions from '@/config/permissions';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@hexa-ui/theme';
import {
  createFeatureToggleServiceV2,
  useAuthenticationService,
  useHasPermission,
} from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { getApolloClient } from './Api/graphql/graphql';
import { FEATURE_TOGGLE_V2_PROJECT_NAME } from './config/featureToggles';
import './global.styles.css';
import useGetDefaultCountry from './hooks/useGetDefaultCountry/useGetDefaultCountry';
import { IntlProvider } from './i18n';
import ToastProvider from './providers/ToastProvider/ToastProvider';
import { useEnvStore } from './stores/useEnvStore/useEnvStore';
import { EnvConfig } from './types/common.types';
import AppBarConfig from './utils/AppBarConfig';

const App = (props: EnvConfig): JSX.Element => {
  const defaultCountry = useGetDefaultCountry(props.supportedCountries);
  const { setEnv, country, setAuthorizedCountry } = useEnvStore(
    ({ setEnv, country, setAuthorizedCountry }) => ({
      setEnv,
      country,
      setAuthorizedCountry,
    })
  );
  const { optimizely_key } = props;
  createFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME, optimizely_key || '');
  const authentication = useAuthenticationService();
  const hasPermission = useHasPermission(permissions.BEES_ADMIN);

  //Set envs
  useEffect(() => {
    setEnv({ ...props, defaultCountry: defaultCountry });
  }, [defaultCountry]);

  //Set Authorized countries
  useEffect(() => {
    if (props.supportedCountries.length === 0 || !authentication) return;

    if (hasPermission) {
      setAuthorizedCountry(props.supportedCountries);
      return;
    }

    const userSupportedCountries = authentication.getSupportedCountries();
    const filteredCountries = props.supportedCountries.filter((country) =>
      userSupportedCountries.includes(country)
    );
    setAuthorizedCountry(filteredCountries);
  }, [props.supportedCountries, authentication]);

  const client = getApolloClient(country);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme="tadaPrimary">
        <IntlProvider>
          <BrowserRouter>
            <ToastProvider>
              <AppBarConfig />
            </ToastProvider>
          </BrowserRouter>
        </IntlProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
