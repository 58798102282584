import { Dispatch, SetStateAction } from 'react';
import { ILatituteLongitude, IPaginationProps } from './common.types';
export interface IDayAvailable {
  day: string;
  available: boolean;
}
export interface IDayWeekWithOpenTimeCloseTime {
  day: string;
  available: boolean;
  times: Array<SellerAdmiBusinessHoursDay>;
}
export interface IWeekDaySetterFunctions {
  monday: Dispatch<SetStateAction<IDayWeekWithOpenTimeCloseTime | undefined>>;
  sunday: Dispatch<SetStateAction<IDayWeekWithOpenTimeCloseTime | undefined>>;
  tuesday: Dispatch<SetStateAction<IDayWeekWithOpenTimeCloseTime | undefined>>;
  wednesday: Dispatch<SetStateAction<IDayWeekWithOpenTimeCloseTime | undefined>>;
  thursday: Dispatch<SetStateAction<IDayWeekWithOpenTimeCloseTime | undefined>>;
  friday: Dispatch<SetStateAction<IDayWeekWithOpenTimeCloseTime | undefined>>;
  saturday: Dispatch<SetStateAction<IDayWeekWithOpenTimeCloseTime | undefined>>;
}
export interface ISellerProps {
  id: string;
  displayName: string;
  legalName: string;
  contacts: Array<ISellerCommon>;
  billingAddress: IAddress;
  deliveryAddress: IAddress;
  additionalInformation?: IAdditionalInformation;
  status: SellerStatusType;
  online: boolean;
  segment: string;
  taxId: string;
  deliveryCenterGroupId: string;
  creatorId: string;
  createdAt: string;
  updatedAt: string;
  coverageArea: Array<ISellerCoordinates>;
  isDynamicCoverageAreaToggleOn: boolean;
  businessHours: ISellerBusinessHours;
  isScheduleEnable: boolean;
  timezone: string;
}
export interface ISellerBusinessHours {
  sunday: Array<SellerAdmiBusinessHoursDay>;
  monday: Array<SellerAdmiBusinessHoursDay>;
  tuesday: Array<SellerAdmiBusinessHoursDay>;
  wednesday: Array<SellerAdmiBusinessHoursDay>;
  thursday: Array<SellerAdmiBusinessHoursDay>;
  friday: Array<SellerAdmiBusinessHoursDay>;
  saturday: Array<SellerAdmiBusinessHoursDay>;
}
export type SellerPropsFormStateAdapterType = Omit<
  ISellerProps,
  'id' | 'status' | 'taxId' | 'deliveryCenterGroupId' | 'creatorId' | 'createdAt' | 'updatedAt'
>;
export interface ISellerList {
  content: Array<ISellerProps>;
  pagination: IPaginationProps;
}

export enum WeekDay {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export interface ScheduleTime {
  weekDay: WeekDay;
  startAt: string;
  finishAt: string;
}
export interface ISellerCoordinates {
  name: string;
  active: boolean;
  coordinates: AzureMapsCoordinatesType | [] | GoogleMapsCoordinatesType;
  scheduleTime?: Array<ScheduleTime>;
}
export type AzureMapsCoordinatesType = Array<Array<number>>;
export type GoogleMapsCoordinatesType = Array<ILatituteLongitude>;
export interface ISellerCommon {
  type: string;
  value: string;
}
export interface IAddress {
  lines: Array<ISellerCommon>;
  city: string;
  state: string;
  zipcode: string;
  latitude: string | number;
  longitude: string | number;
}
export type SellerAddressType = 'deliveryAddress' | 'billingAddress';
export enum SellerAddressEnum {
  deliveryAddress = 'deliveryAddress',
  billingAddress = 'billingAddress',
}
export type SellerAdmiBusinessHoursDay = {
  openAt: string;
  closeAt: string;
};
export interface IAdditionalInformation {
  issuesInvoices: boolean;
  refrigeratorCount: string;
  hasColdRoom: boolean;
  hasServiceEntry: boolean;
}
export type SellerStatusType =
  | 'ALL'
  | 'ACTIVE'
  | 'PENDING'
  | 'INACTIVE'
  | 'REJECTED'
  | 'BLOCKED'
  | '';
export enum SellerStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
}
export enum SellerStatusColorsEnum {
  ACTIVE = '#1F8E4A',
  PENDING = '#FFB042',
  INACTIVE = '#E4423F',
  REJECTED = '#262626',
  BLOCKED = '#808080',
  NEW = '#047AF1',
}
export enum SellerStatusColorsBrightEnum {
  ACTIVE = '#c0f1d3',
  PENDING = '#ffdfb3',
  INACTIVE = '#f5bdbc',
  REJECTED = '#262626',
  BLOCKED = '#d9d9d9',
  NEW = '#E9F1FF',
}
export interface ISellerListFilterParams {
  id?: string;
  page?: number;
  pageSize?: number;
  searchFor?: string | string[];
  searchBy?: string;
  country?: string;
  status?: SellerStatusType;
  taxId?: string[];
  legalName?: string;
  deliveryCenterGroupId?: string;
}
export interface LoadingProps {
  id: string;
}
export type AreaMapType = 'radius' | 'points';
