const SCOPES = {
  DETAILS_READ: 'AdminPortalSeller.Details.Read',
  DETAILS_WRITE: 'AdminPortalSeller.Details.Write',
  ORDERS_READ: 'AdminPortalSeller.Orders.Read',
  GLOBAL_WRITE: 'AdminPortalSeller.Global.Write',
};

const ROLES = {
  DTC_SELLER_READ: [SCOPES.DETAILS_WRITE, SCOPES.DETAILS_READ, SCOPES.ORDERS_READ],
  DTC_SELLER_EDITOR: [SCOPES.GLOBAL_WRITE, SCOPES.DETAILS_WRITE],
};

const PERMISSION_GROUPS = {
  BEES_ADMIN: [...ROLES.DTC_SELLER_READ, ...ROLES.DTC_SELLER_EDITOR],
  COUNTRY_MANAGER: ROLES.DTC_SELLER_READ,
};

export default PERMISSION_GROUPS;
