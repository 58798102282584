/**
 * Convert the First Letter of a String into UpperCase, and the rest in LowerCase
 * @param {string} value
 * @returns Capitalized value
 */
export const capitalizeString = (value: string): string => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

/**
 * Generate a safe random UUUID V4
 * @returns string UUID V4
 */
export const randomUUIDV4 = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string) =>
    (Number(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))).toString(
      16
    )
  );
};

export const normalizeString = (value: string | undefined): string => {
  if (!value) return '';
  return value.trim().toUpperCase();
};