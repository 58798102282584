import { defaultDevtoolsOptions } from '@/config/stores';
import { SearchBy } from '@/types/search.types';
import { ISellerListFilterParams, SellerStatusEnum } from '@/types/seller.types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

const initialState: ISellerListFilterParams = {
  page: 0,
  pageSize: 10,
  searchBy: SearchBy.legalName.type,
  searchFor: '',
  status: SellerStatusEnum.ACTIVE,
  deliveryCenterGroupId: '',
};

export interface ISellerListFilterStore extends ISellerListFilterParams {
  setPage: (page: ISellerListFilterParams['page']) => void;
  setPageSize: (pageSize: ISellerListFilterParams['pageSize']) => void;
  setSearchBy: (searchBy: ISellerListFilterParams['searchBy']) => void;
  setSearchFor: (searchFor: ISellerListFilterParams['searchFor']) => void;
  setStatus: (status: SellerStatusEnum) => void;
  setDeliveryCenterGroupId: (
    deliveryCenterGroupId: ISellerListFilterParams['deliveryCenterGroupId']
  ) => void;
  setFilters: (filters: ISellerListFilterParams) => void;
}

const persistOptions = {
  name: 'sellerListFilterValues',
  storage: createJSONStorage(() => localStorage),
};

export const useSellerListFilterStore = create<ISellerListFilterStore>()(
  persist(
    devtools(
      (set) => ({
        ...initialState,
        setPage: (page) => set({ page }),
        setPageSize: (pageSize) => set({ pageSize }),
        setSearchBy: (searchBy) => set({ searchBy }),
        setSearchFor: (searchFor) => set({ searchFor }),
        setDeliveryCenterGroupId: (deliveryCenterGroupId) => set({ deliveryCenterGroupId }),
        setStatus: (status) => set({ status }),
        setFilters: (filters) => set(filters),
      }),
      { ...defaultDevtoolsOptions, anonymousActionType: 'useSellerListFilterStore' }
    ),
    persistOptions
  )
);
