import { useAuthenticationService } from 'admin-portal-shared-services';

const DEFAULT_LANGUAGE = 'en-US';

function unifyLanguage(language: string) {
  if (language?.startsWith('es-')) {
    return 'es-419';
  }
  if (language?.startsWith('en-')) {
    return 'en-US';
  }
  return language;
}

export default function useMfeGetLocale(): string {
  const authentication = useAuthenticationService();
  const preferredLanguageB2C = authentication.getPreferredLanguageB2C();

  const { user_country } = authentication.getUserCountryAndLanguage();
  const userCountryLanguage = authentication.getLanguageByCountry(user_country ?? '');

  return (
    unifyLanguage(preferredLanguageB2C) || unifyLanguage(userCountryLanguage) || DEFAULT_LANGUAGE
  );
}
