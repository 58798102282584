export enum SearchTypeEnum { TAX_ID, LEGAL_NAME }

export const SearchBy = {
  taxID: {
    type: SearchTypeEnum[SearchTypeEnum.TAX_ID],
    name: 'Tax ID'
  },
  legalName: {
    type: SearchTypeEnum[SearchTypeEnum.LEGAL_NAME],
    name: 'Legal Name'
  },
}