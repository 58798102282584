import { defaultDevtoolsOptions } from '@/config/stores';
import { ILatituteLongitude } from '@/types/common.types';
import { OperationStatusSeller } from '@/types/totalCoverage.types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface ISellerTotalCoverageFilter {
  operationStatus: OperationStatusSeller[];
  deliveryCenter: string;
  coordinates?: ILatituteLongitude;
}

const initialState: ISellerTotalCoverageFilter = {
  operationStatus: [],
  deliveryCenter: '',
};

export interface ISellerTotalCoverageFilterStore extends ISellerTotalCoverageFilter {
  setOperationStatus: (operationStatus: ISellerTotalCoverageFilter['operationStatus']) => void;
  setDeliveryCenter: (deliveryCenter: ISellerTotalCoverageFilter['deliveryCenter']) => void;
  setCoordinates: (coordinates: ISellerTotalCoverageFilter['coordinates']) => void;
}

const persistOptions = {
  name: 'sellerTotalCoverageFilterValues',
  storage: createJSONStorage(() => localStorage),
};

export const useSellerTotalCoverageFilterStore = create<ISellerTotalCoverageFilterStore>()(
  persist(
    devtools(
      (set) => ({
        ...initialState,
        setOperationStatus: (operationStatus) => set({ operationStatus }),
        setDeliveryCenter: (deliveryCenter) => set({ deliveryCenter }),
        setCoordinates: (coordinates) => set({ coordinates }),
      }),
      { ...defaultDevtoolsOptions, anonymousActionType: 'useSellerTotalCoverageFilterStore' }
    ),
    persistOptions
  )
);
