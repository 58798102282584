import MessageMap from '../i18n';

const koKR: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  ItemServiceSection: {
    LoadingMessage: 'Loading',
  },
  sidemenu: {
    home: 'Homepage',
    list: 'Sellers list',
    totalCoverage: 'Total Coverage',
  },
  homePage: {
    title: 'Seller Management',
    list: {
      title: 'Sellers list',
      description: 'Check all the sellers list',
    },
    totalCoverage: {
      title: 'Total Coverage',
      description: 'Information about areas that our POCs are currently covering',
    },
  },
  sellerListPage: {
    title: 'Seller list',
  },
  toggleModal: {
    title: 'Change this seller to "{status}"?',
    text: 'The status of the seller <{seller}> will be set as "{status}"',
    toast: {
      success: 'Seller successfully changed to {status}',
      error: 'Erro to change seller to {status}. Try again.',
    },
    open: 'open',
    close: 'close',
  },
  statusModal: {
    title: 'Change the status to activate',
    text: 'Assign a delivery center to make this seller active.',
    selectPlaceholder: 'Select a delivery center',
    cancelTitle: 'Are you sure you want to cancel?',
    cancelText: "The status of this seller will remain the same and it won't change to active.",
    back: 'No, go back',
    quit: 'Yes, quit',
  },
  typeModal: {
    title: 'Activate Seller',
    text: 'Now, assign a seller type to this seller. This action is needed to change its status to “active”.',
    selectPlaceholder: 'Select a seller type',
    activate: 'Activate',
  },
  buttons: {
    goBack: 'Go back',
    back: 'Back',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    apply: 'Apply',
    copy: 'Copy',
    edit: 'Edit',
    select: 'Select',
    selectOrEdit: 'Select or Edit',
    continue: 'Continue',
    setHours: 'Set hours',
    addHours: '+ Add hours',
    next: 'Next',
    setAreaDefault: 'Set area',
    editAreaDefault: 'Edit area',
    setCoverageAreaScheduled: 'Set or edit',
    enable: 'Enable',
    disable: 'Disable',
  },
  sellerAttributes: {
    displayName: 'Display name',
    legalName: 'Legal name',
    contacts: 'Contacts',
    phone: 'Phone',
    email: 'Email',
    mail: 'Email',
    billingAddress: 'Billing address',
    deliveryAddress: 'Delivery address',
    thoroughfare: 'Street address',
    complement: 'Complement (apt, floor, etc)',
    number: 'Number',
    neighborhood: 'Neighborhood',
    reference: 'Reference',
    city: 'City',
    state: 'State',
    zipcode: 'ZIP/postal code',
    coordinates: 'Coordinates',
    latitude: 'Latitude',
    longitude: 'Longitude',
    status: 'Status',
    online: 'Online',
    sellerType: 'Seller Type',
    taxId: 'Tax ID',
    createdAt: 'Creation date',
    updatedAt: 'Last update',
    fax: 'Fax',
  },
  deliveryCenter: {
    title: 'Delivery center',
    empty: "No delivery center assigned. Change the seller's status to active to assign one.",
    attributes: {
      id: 'ID',
      name: 'Name',
    },
  },
  sellerTable: {
    pagination: {
      quantityIndicator: 'of',
      pageSizeOptions: 'Items per page: {options}',
    },
    empty: {
      title: 'No Sellers found',
      message: "This search didn't find any sellers. Try another one",
    },
    loading: {
      title: 'Loading',
      message: "We are preparing the seller's list for you.",
    },
    filtered: {
      title: 'No Sellers yet',
      message: 'The sellers will be displayed here.',
    },
    error: {
      400: {
        title: 'Oops, something went wrong!',
        message: 'An unexpected error ocurred. Try again.',
      },
      401: {
        title: 'Oops, something went wrong!',
        message: 'Contact our support team to review your account settings.',
      },
      403: {
        title: 'Oops, something went wrong!',
        message: 'Contact our support team to review your account settings.',
      },
      404: {
        title: 'Oops, something went wrong!',
        message: "We couldn't perform this action. Try again.",
      },
      500: {
        title: 'Oops, something went wrong!',
        message: 'An unexpected error ocurred. Try again.',
      },
      502: {
        title: 'Oops, something went wrong!',
        message: 'An unexpected error ocurred. Try again.',
      },
      503: {
        title: 'Oops, something went wrong!',
        message: 'An unexpected error ocurred. Please try again.',
      },
    },
  },
  sellerDetailsPage: {
    store: 'Store',
    open: 'Open',
    closed: 'Closed',
    generalInfo: 'General information',
    editToast: {
      success: "Seller's information successfully changed.",
      error: 'Error on updated seller info. Try again.',
    },
    statusToast: {
      success: "Seller's status successfully changed",
      error: 'Error on change seller status. Try again.',
    },
    deliveryCenterToast: {
      success: 'Delivery center successfuly assigned',
      error: 'Error on assign the Delivery center. Try again.',
    },
    businessHoursMessage: 'To activate this seller, set up  business hours.',
  },
  countries: {
    HN: 'Honduras',
    SV: 'El Salvador',
    PA: 'Panama',
    BO: 'Bolivia',
    DO: 'Dominican Republic',
    PY: 'Paraguay',
    PE: 'Peru',
    EC: 'Ecuador',
    ZA: 'South Africa',
    CO: 'Colombia',
    MX: 'Mexico',
    AR: 'Argentina',
    BR: 'Brazil',
  },
  filters: {
    placeholders: {
      country: 'Select a country',
      deliveryCenter: 'All delivery centers',
      status: 'Select a status',
    },
    status: {
      ALL: 'All statuses',
      ACTIVE: 'Active',
      PENDING: 'Pending',
      INACTIVE: 'Inactive',
      REJECTED: 'Rejected',
      BLOCKED: 'Blocked',
    },
  },
  fields: {
    optional: 'Optional',
    required: {
      generic: 'Required field',
      displayName: 'Enter a display name',
      city: "Enter the city's name",
      state: "Enter the state's name",
      zipcode: 'Enter the ZIP/postal code',
      latitute: 'Enter the latitude number',
      longitude: 'Enter the longitude number',
    },
    format: {
      generic: 'Invalid format',
      latitute: 'The latitude number must be between -90 and 90',
      longitude: 'The longitude number must be between -180 and 180',
    },
  },
  unauthorized_page: {
    title: 'Oops, access denied!',
    description: "It seems you don't have permission to access this page.",
    action: 'Go back to the homepage',
  },
  coverageArea: {
    title: 'Coverage area',
    selectOrEdit: 'Select or edit coverage area',
    helpText:
      "For the seller's location, we consider the coordinates informed in the delivey address",
    mapCaption: {
      title: 'Map caption',
      store: 'Store',
    },
    createArea: {
      title: 'Create an area',
      description: 'Create a coverage area by drawing a circle and adjusting the edit points',
      descriptionMultiPolygon:
        'Create a coverage area by drawing a circle and adjusting the edit points, or select a new active coverage area',
      newAreaAction: 'Create a new area',
      createOrSelectAnArea: 'Create or Select an area',
      selectAnActiveCoverageArea: 'Select an active coverage area',
    },
    editArea: {
      title: 'Edit area',
      description: 'Change the coverage area by drawing a circle and adjusting the edit points',
      nameInput: 'Coverage area name',
      nameInputPlaceholder: 'Enter an area name',
      namePlaceholder: 'Regular hours',
      nameError:
        'You already have a coverage area with the same name. Enter a different one to continue.',
      editSelectedCoverageArea: 'Edit selected coverage area',
      editingActions: {
        title: 'Edit the area using:',
        radius: 'Radius (Km)',
        editPoints: 'Editing points',
        editPointsDescription:
          'Moving the editing points will reset the radius the next time you select this option.',
        radiusDescription: 'Recommended radius: 9km',
        deleteArea: 'Delete area',
      },
      deleteCoverageAreaWithAreaName: 'Do you want to delete <{area}>?',
      deleteCoverageArea: 'Delete coverage area',
      selectCoverageArea:
        'Do you want <{area}> to become the active coverage area for this seller?',
      changeActiveCoverageArea: 'Change active coverage area',
    },
    editAreaDialog: {
      title: 'Edit coverage area',
      titleSelectOrEdit: 'Select or edit coverage area',
      subtitle: 'You must edit the coverage area so the seller can be activated.',
    },
    toast: {
      success: 'Active coverage area successfully changed.',
      error: 'We couldn’t change the active coverage area. Try again',
    },
  },
  storeStatus: {
    ACTIVE: 'Active',
    PENDING: 'Pending',
    INACTIVE: 'Inactive',
    REJECTED: 'Rejected',
    BLOCKED: 'Blocked',
  },
  defaultCoverageArea: {
    title: 'Default coverage area',
    helpText:
      'This is the main delivery area. It will be active when there are no active schedules.',
    helpTextModal:
      'Main delivery area. While there are no active schedules, the default area will remain active',
    form: {
      titleCreate: 'Create an area',
      titleEdit: 'Edit an area',
      description: 'Create a coverage area by drawing a circle and moving its edit points.',
      placeholderName: 'Default coverage area',
      labelName: 'Coverage area name',
      typeCoordinatesLabel: 'Edit the area using…',
      buttonSave: 'Save',
      butttonCancel: 'Back',
    },
    toast: {
      successCreate: 'Default coverage area successfully created',
      successEdit: 'Default coverage area successfully edited',
      error: 'It was not possible to complete the action. Try again',
    },
    confirmDefaultCoverageAreaDialog: {
      title: 'Create default area',
      subtitle: 'You must to set the default area so the store can be activated.',
      buttonConfirm: 'Create area',
      buttonBack: 'Back',
    },
  },
  scheduledCoverageArea: {
    title: 'Scheduled area',
    titleModal: 'Scheduled coverage area',
    helpText:
      'Temporary coverage area with defined days and times. On all other days and times, the store operates with the standard delivery area.',
    helpTextModal:
      'Set a temporary delivery area for specific days and times. When inactive, deliveries revert to the default coverage area.',
    emptyContent: "This store doesn't have scheduled areas yet",
    filledContent: 'Create new areas with schedules or edit the existing ones.',
    form: {
      titleCreate: 'Create an area',
      titleEdit: 'Edit an area',
      description:
        'Create a coverage area with scheduling by drawing a circle, moving its edit points, and choosing days and times for it to be active.',
      labelName: 'Coverage area name (required)',
      placeholderName: 'Enter an area name',
      helperTextName: 'The name of the area cannot be changed later.',
      typeCoordinatesLabel: 'Edit the area using…',
      buttonSave: 'Save',
      butttonCancel: 'Back',
      buttonRemove: 'Delete area',
      schedulingLabel: 'Scheduling (required)',
      schedulingText: 'Manage scheduling',
      errors: {
        name: 'The name has already been used in another area.',
      },
    },
    scheduling: {
      title: 'Scheduling',
      subtitle: 'Settings',
      infoText: 'Select the day and start and end times to schedule the area',
      addText: '+ Add new date and time',
      dayLabel: 'Day of the week',
      startAtLabel: 'Start time',
      endAtLabel: 'End time',
      errors: {
        alreadySelected: 'That time period is already scheduled for another area',
      },
    },
    toast: {
      successCreate: 'Scheduled coverage area successfully created',
      successEdit: 'Scheduled coverage area successfully edited',
      successRemove: 'Scheduled coverage area successfully deleted',
      error: 'It was not possible to complete the action. Try again',
    },
    manageArea: {
      title: 'Create an area',
      description:
        'Create a coverage area with scheduling by drawing a circle, moving its edit points, and choosing days and times for it to be active.',
      buttonCreateNewArea: 'Create a new area',
      editAreaLabel: 'Select an area to edit',
      editSelectedAreaButtonLabel: 'Edit selected coverage area',
      daysAndHoursSelectedAreaCard: {
        title: 'Days and times when the area will be activated',
        textBetweenStartAtAndFinishAt: 'to',
      },
    },
    toggle: {
      text: 'Create new areas with schedules or edit the existing ones.',
      enable: {
        title: 'Are you sure you want to enable the scheduled area?',
        firstText: 'By enabling this functionality, the area will be indicated with the tag',
        secondText: 'when the scheduled time arrives.',
      },
      disable: {
        title: 'Are you sure you want to disable the scheduled area?',
        text: "By disabling this functionality, scheduled areas won't  be automatically activated when the scheduled time starts.",
      },
    },
    timezoneInfo: {
      nameTitle: 'This store belongs to',
      nameValue: 'Time Zone:',
      currentTime: 'Current Time',
    },
  },
  weekDays: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  businessHours: {
    title: 'Business hours',
    edit: 'Edit business hours',
    empty: "Business hours haven't been set. This action is needed to activate this seller.",
    opensAt: 'Opens at',
    closesAt: 'Closes at',
    toast: {
      success: 'Business hours updated.',
      error: 'Unable to update business hours. Try again.',
    },
  },
  totalCoveragePage: {
    title: 'Total coverage',
    filters: {
      filteredBy: 'Filtered by',
      country: 'Country',
      filterButton: 'Filter',
      searchPlaceholder: 'Search address',
      searchButton: 'Search',
      buttonApplyFilter: 'Apply',
      buttonCancel: 'Cancel',
      operationStatus: 'Current Status',
      deliveryCenters: 'Delivery Centers',
      AlldeliveryCenters: 'All delivery centers',
    },
    updateMapButton: 'Update map',
    mapLoading: {
      title: 'Loading',
      text: 'We are preparing the map for you.',
    },
    operationStatus: {
      CLOSED: 'Closed',
      OPEN: 'Open',
      SHOULD_BE_OPEN: 'Should be open',
    },
    lastUpdated: 'Last updated:',
    toastErrorLoading: 'Unable to load your request. Please try again',
    sellerDetails: {
      phone: 'Phone',
      buttonGoToStore: 'Go to store',
      businessHours: 'Business hours',
      businessHoursNotProvided: 'Business hours not provided',
    },
    sellerList: {
      numberSellers: 'Number of points of sale in this area:',
      showMore: '(go to the store)',
    },
  },
};

export default koKR;
