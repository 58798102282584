import Can from '@/components/Can/Can';
import { SELLER_ROUTES } from '@/config/constants';
import { TOGGLE_LIST } from '@/config/featureToggles';
import permissions from '@/config/permissions';
import { Error403, LoadingBuzz } from '@hexa-ui/components';
import { Suspense, lazy } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { Fallback } from './Router.styles';

const SellerListPage = lazy(() => import('@/pages/SellerListPage/SellerListPage'));
const SellerDetailsPage = lazy(() => import('@/pages/SellerDetailsPage/SellerDetailsPage'));
const SellerHomePage = lazy(() => import('@/pages/SellerHomePage/SellerHomePage'));
const SellerTotalCoveragePage = lazy(
  () => import('@/pages/SellerTotalCoveragePage/SellerTotalCoveragePage')
);

export const fallback = (
  <Fallback>
    <LoadingBuzz />
  </Fallback>
);

const Router = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const errorPage = (
    <Error403
      header={formatMessage({ id: 'unauthorized_page.title' })}
      description={formatMessage({ id: 'unauthorized_page.description' })}
      buttonText={formatMessage({ id: 'unauthorized_page.action' })}
    />
  );

  return (
    <Suspense fallback={fallback}>
      <Can performs={[permissions.BEES_ADMIN, permissions.COUNTRY_MANAGER]} fallback={errorPage}>
        <Routes>
          <Route
            path={SELLER_ROUTES.HOME}
            element={
              <Suspense fallback={fallback}>
                <SellerHomePage />
              </Suspense>
            }
          />
          <Route
            path={SELLER_ROUTES.LIST}
            element={
              <Suspense fallback={fallback}>
                <SellerListPage />
              </Suspense>
            }
          />
          <Route
            path={SELLER_ROUTES.TOTALCOVERAGE}
            element={
              <Suspense fallback={fallback}>
                <Can featureToggle={TOGGLE_LIST.TOTAL_COVERAGE}>
                  <SellerTotalCoveragePage />
                </Can>
              </Suspense>
            }
          />
          <Route
            path={SELLER_ROUTES.DETAILS}
            element={
              <Suspense fallback={fallback}>
                <SellerDetailsPage />
              </Suspense>
            }
          />
        </Routes>
      </Can>
    </Suspense>
  );
};
export default Router;
