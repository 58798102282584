import PERMISSION_GROUPS from '@/config/permissions';
import { useAuthenticationService, useHasPermission } from 'admin-portal-shared-services';

const useGetDefaultCountry = (supportedCountries: Array<string>): string => {
  const authentication = useAuthenticationService();
  const hasPermission = useHasPermission(PERMISSION_GROUPS.BEES_ADMIN);

  if (hasPermission) {
    return supportedCountries[0];
  } else {
    const userSupportedCountries = authentication.getSupportedCountries();
    const filteredCountries = supportedCountries.filter((country) =>
      userSupportedCountries.includes(country)
    );
    return filteredCountries[0];
  }
};
export default useGetDefaultCountry;
