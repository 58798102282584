export const TEST_COORDINATES = [
  [-43.94487, -19.937549999999998],
  [-43.942790883091824, -19.937768523992663],
  [-43.94080263356924, -19.938414545423573],
  [-43.93899214747708, -19.93945983005625],
  [-43.93743855174523, -19.940858693936413],
  [-43.93620974596216, -19.94255],
  [-43.93535943483705, -19.94445983005625],
  [-43.93492478104632, -19.946504715367322],
  [-43.93492478104632, -19.948595284632678],
  [-43.93535943483705, -19.95064016994375],
  [-43.93620974596216, -19.95255],
  [-43.93743855174523, -19.954241306063587],
  [-43.93899214747708, -19.95564016994375],
  [-43.94080263356924, -19.956685454576427],
  [-43.942790883091824, -19.957331476007337],
  [-43.94487, -19.95755],
  [-43.94694911690818, -19.957331476007337],
  [-43.94893736643076, -19.956685454576427],
  [-43.95074785252292, -19.95564016994375],
  [-43.95230144825477, -19.954241306063587],
  [-43.953530254037844, -19.95255],
  [-43.95438056516295, -19.95064016994375],
  [-43.954815218953684, -19.948595284632678],
  [-43.954815218953684, -19.946504715367322],
  [-43.95438056516295, -19.94445983005625],
  [-43.953530254037844, -19.94255],
  [-43.95230144825477, -19.940858693936413],
  [-43.95074785252292, -19.93945983005625],
  [-43.94893736643076, -19.938414545423573],
  [-43.94694911690818, -19.937768523992663],
  [-43.94487, -19.937549999999998],
];

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const NAME_DEFAULT = 'DEFAULT_AREA';

export const HALF_DAY = 12;

export const LOCALES_FORMAT_HOUR12 = {
  'pt-BR': false,
  'es-419': false,
  'en-US': true,
  'ko-KR': true,
};

export const ROUTER_BASENAME = '/seller';

export const SELLER_ROUTES = {
  HOME: ROUTER_BASENAME,
  LIST: `${ROUTER_BASENAME}/list`,
  DETAILS: `${ROUTER_BASENAME}/:id`,
  TOTALCOVERAGE: `${ROUTER_BASENAME}/total-coverage`,
};
