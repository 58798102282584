import { SELLER_ROUTES } from '@/config/constants';
import { TOGGLE_LIST } from '@/config/featureToggles';
import useFeatureToggle from '@/hooks/useFeatureToggle/useFeatureToggle';
import { Globe, Home, Users } from '@hexa-ui/icons';
import { useSidebar as useSideBarService } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';

export const HomeIcon = (): React.ReactElement => <Home />;
export const SellerListIcon = (): React.ReactElement => <Users />;
export const TotalCoverageIcon = (): React.ReactElement => <Globe />;

const useSidebar = () => {
  const { formatMessage } = useIntl();
  const canRenderTotalCoverage = useFeatureToggle(TOGGLE_LIST.TOTAL_COVERAGE);
  const items = [
    {
      id: 'home',
      title: formatMessage({ id: 'sidemenu.home' }),
      icon: HomeIcon,
      path: SELLER_ROUTES.HOME,
    },
    {
      id: 'list',
      title: formatMessage({ id: 'sidemenu.list' }),
      icon: SellerListIcon,
      path: SELLER_ROUTES.LIST,
    },
  ];

  if (canRenderTotalCoverage) {
    items.push({
      id: 'totalCoverage',
      title: formatMessage({ id: 'sidemenu.totalCoverage' }),
      icon: TotalCoverageIcon,
      path: SELLER_ROUTES.TOTALCOVERAGE,
    });
  }

  return useSideBarService({ items, utils: [] });
};

export default useSidebar;
