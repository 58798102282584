import { rest } from 'msw';
import { SELLER_SERVICE_BASE_URL } from '../../../config/api';
import { sellerListMock } from './sellerMocks';

export const getSellersHandler = [
  rest.get(`${SELLER_SERVICE_BASE_URL}/`, (req, res, ctx) => {
    const params = req.url.searchParams;
    const sellerId = req.url.searchParams.get('id');

    let responseBody;

    if (!sellerId) {
      const filterValues = JSON.parse(sessionStorage?.getItem('sellerFilterValues'));

      let filteredSellers = [];
      let paginatedSellers = [];

      if (filterValues.deliveryCenterGroupId) {
        filteredSellers = sellerListMock.content.filter(
          (seller) => seller.deliveryCenterGroupId === filterValues.deliveryCenterGroupId
        );
      }

      if (filterValues.status) {
        const valuesToFilter = filterValues.deliveryCenterGroupId
          ? filteredSellers
          : sellerListMock.content;
        filteredSellers = valuesToFilter.filter((seller) => seller.status === filterValues.status);
      }

      paginatedSellers = filteredSellers.splice(
        filterValues.page * filterValues.pageSize,
        filterValues.pageSize
      );

      responseBody = {
        content: paginatedSellers,
        pagination: {
          page: filterValues.page,
          pageSize: filterValues.pageSize,
          totalElements: paginatedSellers.length,
          totalPages: Math.ceil(paginatedSellers.length / +params.get('pageSize')),
        },
      };
    } else {
      const foundSeller = sellerListMock.content.find((seller) => seller.id === sellerId);
      responseBody = {
        content: [foundSeller],
        pagination: {
          page: 0,
          pageSize: 10,
          totalElements: 1,
          totalPages: 1,
        },
      };
    }

    return res(ctx.status(200), ctx.json(responseBody));
  }),
];

export const updateSellerStatusHandler = [
  rest.put(`${SELLER_SERVICE_BASE_URL}/status/:status`, (req, res, ctx) => {
    const sellerId = req.body[0];
    const statusToUpdate = req.params.status;

    const foundSeller = sellerListMock.content.find((seller) => seller.id === sellerId);
    foundSeller.status = statusToUpdate;

    return res(ctx.status(204));
  }),
];

export const updateSellerHandler = [
  rest.patch(`${SELLER_SERVICE_BASE_URL}/:id`, (req, res, ctx) => {
    const sellerId = req.params.id;
    const editedSeller = JSON.parse(JSON.stringify(req.body));

    let foundSeller = sellerListMock.content.find((seller) => seller.id === sellerId);
    foundSeller = editedSeller;

    console.log(foundSeller);

    return res(ctx.status(204));
  }),
];

export const updateSellerDeliveryCenterHandler = [
  rest.post(`${SELLER_SERVICE_BASE_URL}/bind-delivery-center-groups`, (req, res, ctx) => {
    const { deliveryCenterGroupId, id } = req.body[0];

    const foundSeller = sellerListMock.content.find((seller) => seller.id === id);
    foundSeller.deliveryCenterGroupId = deliveryCenterGroupId;

    return res(ctx.status(204));
  }),
];
